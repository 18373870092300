.parent_register {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 50px #ccc;
  padding: 3rem;
  border-radius: 15px;
  width: 32vw;
}

.parent_register label {
  font-weight: 600;
  font-size: 1rem;
}

.parent_register .logo_width {
  width: 10rem;
}

::placeholder {
  color: hsl(0, 0%, 50%) !important;
}

.form-control:focus {
  box-shadow: none !important;
  border-color: #183541 !important;
}

/* #183541 */
.bg-btn {
  background-color: #183541 !important;
  border: none !important;
}

.bg-btn:hover {
  background-color: #1e5066 !important;
  border: none !important;
}

.staric {
  color: red;
}

.error-text {
  font-weight: 500;
  margin-top: 2px;
  font-size: 0.875rem;
  padding-left: 1.875rem;
}

@media (max-width: 1024px) {
  .parent_register {
    width: 60%;
    margin: 4rem 0;
  }
}

@media (max-width: 500px) {
  .parent_register {
    width: 90%;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.pas-icon-position {
  position: absolute;
  right: 10px;
  top: 5px;
}

.font-sm {
  font-size: 14px !important;
  color: rgb(126, 122, 122);
}
