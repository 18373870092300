.parent_privacy_policy {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100vh !important;
  width: 85%;
}

.logo_width {
  width: 13rem;
}

.text-center {
  text-align: center;
}

@media only screen and (min-width: 600px) {
  .parent_privacy_policy {
    width: 60%;
  }
}
